import styled from '@emotion/styled'
import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import { LEGACY_EOLA_ROUTES, EOLA_ROUTES } from 'enums/routes'
import Icon from 'shared/library/atoms/Icon'

const EolaFooter = () => {
  const { t } = useTranslation('common')

  return (
    <StyledFooter>
      <StyledWrapper>
        <StyledFooterColumn>
          <StyledLink href={LEGACY_EOLA_ROUTES.BUSINESS_HOME.url()}>
            <StyledLogo name="logo" color="white" height={26} width={70} />
          </StyledLink>
        </StyledFooterColumn>
        <StyledFooterColumn>
          <StyledColumnTitle>{t('about')}</StyledColumnTitle>
          <StyledLink href={EOLA_ROUTES.ABOUT_US.url()}>
            {t('about-us')}
          </StyledLink>
          <StyledLink href={LEGACY_EOLA_ROUTES.SETUP.url()}>
            {t('getting-set-up')}
          </StyledLink>
          <StyledLink href={LEGACY_EOLA_ROUTES.TERMS_CONDITIONS.url()}>
            {t('terms-and-conditions')}
          </StyledLink>
        </StyledFooterColumn>

        <StyledFooterColumn>
          <StyledColumnTitle>{t('solutions')}</StyledColumnTitle>
          <StyledLink
            href={LEGACY_EOLA_ROUTES.ACTIVITIES_AND_EXPERIENCES.url()}
          >
            {t('activities-and-experiences')}
          </StyledLink>
          <StyledLink href={LEGACY_EOLA_ROUTES.TREK_AND_EXPEDITIONS.url()}>
            {t('trek-and-expeditions')}
          </StyledLink>
          <StyledLink href={LEGACY_EOLA_ROUTES.COURSES_AND_LESSONS.url()}>
            {t('courses-and-lessons')}
          </StyledLink>
          <StyledLink href={LEGACY_EOLA_ROUTES.TOURS_AND_ATTRACTIONS.url()}>
            {t('tours-and-attractions')}
          </StyledLink>
          <StyledLink href={LEGACY_EOLA_ROUTES.RENTALS.url()}>
            {t('rentals')}
          </StyledLink>
          <StyledLink href={LEGACY_EOLA_ROUTES.MEMBERSHIPS.url()}>
            {t('memberships')}
          </StyledLink>
          <StyledLink href={LEGACY_EOLA_ROUTES.MULTI_SITE.url()}>
            {t('multi-site')}
          </StyledLink>
        </StyledFooterColumn>

        <StyledFooterColumn>
          <StyledColumnTitle>{t('technology')}</StyledColumnTitle>
          <StyledLink href={LEGACY_EOLA_ROUTES.PRODUCT_BUILDER.url()}>
            {t('product-builder')}
          </StyledLink>
          <StyledLink href={LEGACY_EOLA_ROUTES.BOOKINGS.url()}>
            {t('bookings')}
          </StyledLink>
          <StyledLink href={LEGACY_EOLA_ROUTES.INSTRUCTORS.url()}>
            {t('instructors')}
          </StyledLink>
          <StyledLink href={LEGACY_EOLA_ROUTES.MARKETING.url()}>
            {t('marketing')}
          </StyledLink>
          <StyledLink href={LEGACY_EOLA_ROUTES.REPORTING.url()}>
            {t('reporting')}
          </StyledLink>
          <StyledLink href={LEGACY_EOLA_ROUTES.AI.url()}>
            {t('eola-ai')}
          </StyledLink>
        </StyledFooterColumn>

        <StyledFooterColumn>
          <StyledColumnTitle>{t('resources')}</StyledColumnTitle>
          <StyledLink href={LEGACY_EOLA_ROUTES.BLOG.url()}>
            {t('blog')}
          </StyledLink>
          <StyledLink href={LEGACY_EOLA_ROUTES.ACADEMY.url()}>
            {t('academy')}
          </StyledLink>
          <StyledLink href={LEGACY_EOLA_ROUTES.PARTNERSHIPS.url()}>
            {t('partnerships')}
          </StyledLink>
        </StyledFooterColumn>

        <StyledFooterColumn>
          <StyledImage
            width={200}
            height={70}
            src={
              'https://res.cloudinary.com/eola/image/upload/v1665140992/eola/public_images/eola-footer.svg'
            }
          />
        </StyledFooterColumn>
      </StyledWrapper>
    </StyledFooter>
  )
}

export default EolaFooter

const StyledFooter = styled.footer(({ theme }) => ({
  color: theme.colors.eola.greys[50],
  backgroundColor: theme.colors.black,
  padding: theme.space[6],
  [theme.mediaQueries.md]: {
    padding: theme.space[10]
  }
}))

const StyledLogo = styled(Icon)(({ theme }) => ({
  marginTop: theme.space[5]
}))

const StyledWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: theme.space[6],
  ...theme.mixins.centeredContainer('xxxl'),
  '> div:not(:first-of-type)': {
    padding: theme.space[3],
    [theme.mediaQueries.md]: {
      padding: theme.space[0]
    }
  },
  [theme.mediaQueries.lg]: {
    flexDirection: 'row'
  }
}))

const StyledFooterColumn = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.space[2],
  [theme.mediaQueries.lg]: {
    textAlign: 'left',
    ':last-of-type': {
      textAlign: 'center'
    }
  }
}))

const StyledLink = styled.a(({ theme }) => ({
  color: theme.colors.text.tertiary,
  padding: theme.mixins.pxSpread([theme.space[2], theme.space[3]]),
  ...theme.fontStyle.p3,
  ...theme.mixins.eola.link,
  textDecoration: 'none',
  ':hover': {
    textDecoration: 'none',
    color: theme.colors.white
  }
}))

const StyledColumnTitle = styled.h5(({ theme }) => ({
  color: theme.colors.eola.greys[50],
  padding: theme.mixins.pxSpread([theme.space[2], theme.space[3]]),
  marginBottom: theme.space[0],
  ...theme.fontStyle.h5
}))

const StyledImage = styled.img(({ theme }) => ({
  marginTop: theme.space[5],
  width: 200,
  height: 70,
  [theme.mediaQueries.lg]: {
    marginRight: 'auto'
  }
}))
